import React from "react";
import {poweredByHollrs} from "./powered-by-hollrs";

export const renderHollrSubmitting = function (decodedData: any, compactView: boolean, hollrsLogo: string) {
  return (
    <div>
      <p>Submitting your Hollr</p>
      <div style={{paddingTop: "40px"}}>
        <img src="/images/loading.gif" alt="Loading..." style={{width: "64px", height: "64px"}}/>
      </div>
    </div>
  );
}
