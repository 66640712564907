// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyADxe5NIRTNWMNLYRtOqWQ1Bzs3obvrpwk",
  authDomain: "hollrs-app.firebaseapp.com",
  projectId: "hollrs-app",
  storageBucket: "hollrs-app.appspot.com",
  messagingSenderId: "625419389223",
  appId: "1:625419389223:web:f16043d5321304fef04102",
  measurementId: "G-FEF45D139C",
  qrCodeDataFunction: "https://europe-west2-hollrs-app.cloudfunctions.net/qrCodeData",
};
