// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import {firebaseConfig} from "./firebase-config";

const DEBUG = true;
const REGION = 'europe-west2';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firebaseFunctions = getFunctions(app, REGION);

export const signInUser = async (
  email: string,
  password: string
) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback:NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);

export const addPost = async (postData: any, authorID: string) => {
  if (DEBUG) {console.time('FIREBASE: addPost');}
  if (DEBUG) {console.log('FIREBASE: addPost', postData, authorID);}
  const instance = await httpsCallable(firebaseFunctions, 'addPost', postData);
  const res = await instance({
    authorID,
    postData: postData,
  });
  if (DEBUG) {console.log('FIREBASE: addPost result', res?.data);}

  if (DEBUG) {console.timeEnd('FIREBASE: addPost');}
  return res?.data;
};

export const createUserDoc = async (userId: string, data: any) => {
  if (DEBUG) {console.time('FIREBASE: createUserDoc');}
  if (DEBUG) {console.log('FIREBASE: createUserDoc', userId, data);}

  const postData: any = {
    userID: userId,
    userData: data,
  }
  const instance = httpsCallable(firebaseFunctions, 'createUser');
  const res = await instance(postData);
  if (DEBUG) {console.log('FIREBASE: createUserDoc result', res?.data);}

  if (DEBUG) {console.timeEnd('FIREBASE: createUserDoc');}
  return res?.data;
};
