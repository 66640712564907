import React from "react";

export const poweredByHollrs = function(compactView: boolean, hollrsLogo: string) {
  return <>
    {compactView
      ?
      <div style={{marginBottom: "10px", marginTop: "10px", fontSize: 14}}>
        Powered by
        <a href={"https://hollrs.com"} target="_blank" rel="noreferrer">
          <img src={hollrsLogo} alt="logo" style={{height: "14px", marginLeft: "4px"}}/>
        </a>
      </div>
      :
      <div style={{marginBottom: "10px", marginTop: "10px",}}>
        Powered by
        <div>
          <a href={"https://hollrs.com"} target="_blank" rel="noreferrer">
            <img src={hollrsLogo} alt="logo" style={{width: "100px"}}/>
          </a>
        </div>
      </div>
    }
  </>;
}
