import React from "react";

export const renderVerifyingHollr = function(handleSubmitOtp: (e: React.FormEvent<HTMLFormElement>) => void, ref: React.MutableRefObject<HTMLFormElement>, otp: string, setOtp: (value: (((prevState: string) => string) | string)) => void, compactView: boolean, submitting: boolean) {
  return <form onSubmit={handleSubmitOtp} ref={ref}>
    <div className="App-verifying">
      <p style={{paddingTop: "10px"}}>
        Please verify you are a real person by entering the code we sent you via email:
      </p>
      <div style={{paddingTop: "50px"}}>
        <input
          value={otp}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{6}"
          max={999999}
          placeholder="Enter code"
          onChange={(e) => {
            setOtp(e.target.value)
          }}
          style={{
            fontSize: compactView ? "20px" : "32px",
            width: "220px",
            textAlign: "center",
          }}
        />
      </div>
      <div style={{paddingTop: "40px"}}>
        <button type="submit" disabled={otp?.length !== 6 || submitting}>Submit code</button>
      </div>
    </div>
  </form>;
}
