import React from "react";

export const renderHollrSent = function (decodedData: any, compactView: boolean, hollrsLogo: string, setHollrViewPassword: any) {
  return (
    <div>
      <div>
        <p>
          View your hollr in the app and join the conversation.
        </p>
      </div>
      <div>
        <a href="https://apps.apple.com/gb/app/hollrs/id1668327933" target="_blank" rel="noreferrer">
          <img
            src="/images/Download_on_the_App_Store.svg"
            style={{width: "180px"}}
            alt="Download on the App Store"
          />
        </a>
      </div>
      <div style={{paddingTop: "10px"}}>
        <a href="https://play.google.com/store/apps/details?id=com.hollrs.app" target="_blank" rel="noreferrer">
          <img
            src="/images/google-play-badge.png"
            style={{
              width: "210px"
            }}
            alt="Download on Google Play"
          />
        </a>
      </div>
      {setHollrViewPassword && (<>
        <div>
          <p>
            We've emailed your username password!
          </p>
        </div>
        <div>
          <button onClick={setHollrViewPassword}>View Password</button>
        </div>
      </>)}
    </div>
  );
}
