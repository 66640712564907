import {poweredByHollrs} from "./powered-by-hollrs";

export const AppBody = function ({decodedData, compactView, hollrsLogo, children}: any) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minHeight: "100vh",
      justifyContent: "space-between",
    }}>
      <div>
        <div style={{
          backgroundColor: decodedData?.headerBackgroundColor || "#fff",
          color: decodedData?.headerColor || "#000",
          fontSize: compactView ? "18px" : "22px",
          padding: "30px",
          marginBottom: "20px",
        }}>
          <div>
            <img src={decodedData?.logo || hollrsLogo} alt="logo" style={{width: decodedData?.logoWidth || "50%"}}/>
          </div>
          {children}
        </div>
      </div>
      {poweredByHollrs(compactView, hollrsLogo)}
    </div>
  );
}
