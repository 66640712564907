import React from "react";

export const renderHollrViewUsername = function (decodedData: any, compactView: boolean, hollrsLogo: string, email: string, password: string) {
  return (
    <div>
      <div>
        <p>
          Here are your login details:
        </p>
      </div>
      <div>
        <p>
          <b>Username:</b> {email}<br/>
          <b>Password:</b> {password}
        </p>
      </div>
      <div>
        <p>
          Download the app to get started
        </p>
      </div>
      <br/>
      <div>
        <a href="https://apps.apple.com/gb/app/hollrs/id1668327933" target="_blank" rel="noreferrer">
          <img
            src="/images/Download_on_the_App_Store.svg"
            style={{width: "180px"}}
            alt="Download on the App Store"
          />
        </a>
      </div>
      <div style={{paddingTop: "10px"}}>
        <a href="https://play.google.com/store/apps/details?id=com.hollrs.app" target="_blank" rel="noreferrer">
          <img
            src="/images/google-play-badge.png"
            style={{
              width: "210px"
            }}
            alt="Download on Google Play"
          />
        </a>
      </div>
    </div>
  );
}
