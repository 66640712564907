import React from 'react';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import {addPost, auth, createUserDoc} from './firebase';
import {firebaseConfig} from './firebase-config';
import {AuthContext} from './auth-context'

//import 'react-phone-number-input/style.css'
//import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input/mobile'
import './App.css';
import './form.css';
import {validEmail} from "./valid";
import axios from "axios";

import {AppBody} from "./app-body";
import {renderHollrSent} from "./hollr-sent";
import {renderVerifyingHollr} from "./hollr-verifying";
import {contrastFromColor} from "./contrastFromColor";
import {renderHollrSubmitting} from "./hollr-submitting";
import {renderHollrViewUsername} from "./hollr-view-username";

// hard coded data from old qr codes
const cData: any = {
  "43EnumY": {
    tags: [
      "ozblu",
      "blueberries",
    ],
    hiddenTags: [
      "bin"
    ],
    logo: "/images/ozblu.png",
    backgroundColor: "#232841",
    color: "#fff",
    subTitle: "Talk to the grower"
  },
  "EyFMAtM": {
    tags: [
      "ozblu",
      "blueberries",
    ],
    hiddenTags: [
      "clamshell"
    ],
    logo: "/images/ozblu.png",
    backgroundColor: "#232841",
    color: "#fff",
    subTitle: "Talk to the grower"
  },
  "jiq72YzRs8yjTZjgy": {
    tags: [
      "ozblu"
    ],
    hiddenTags: [
      "batch122",
      "uk",
      "m&s"
    ],
    poi: {
      coordinate: {
        latitude: 52.2786658,
        longitude: -1.5470525,
      },
      name: "M&S Foodhall",
      placeId: "ChIJO7uMOso1d0gR5pfqephOamk",
      target: 0,
    },
    logo: "/images/ozblu.png",
    backgroundColor: "#232841",
    color: "#fff",
    subTitle: "Talk to the grower"
  }
}

function App() {
  const ref = React.useRef<HTMLFormElement>(null!);
  const {currentUser, signOut} = React.useContext(AuthContext)

  const urlData = window.location.pathname.replace('/', '')

  const [data, setData] = React.useState<any>(null)
  const [useEmailAddress, setUseEmailAddress] = React.useState<string>("")

  const [loading, setLoading] = React.useState(true)
  const [compactView, setCompactView] = React.useState(window.location.host === 'p.hollrs.com');

  // React.useEffect(() => {
  //   // TEMP TEMP TEMP
  //   setCompactView(true);
  // }, []);

  React.useEffect(() => {
    if (urlData && urlData.length < 32) {
      // try to load the qrData from the server, otherwise return the urlData
      axios.post(firebaseConfig.qrCodeDataFunction, {
        data: {
          code: urlData
        }
      })
        .then((response) => {
          console.log('response', response)
          if (response?.data?.result?.id) {
            setData(response.data.result)
            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get('compact')) {
              setCompactView(true)
            }
            if (urlParams.get('email')) {
              setUseEmailAddress(urlParams.get('email') || '')
            }
          } else {
            // @ts-ignore
            setData(urlData);
          }
        })
        .catch((error) => {
          console.error('error', error)
          // @ts-ignore
          setData(urlData);
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setData(urlData);
      setLoading(false);
    }
  }, [urlData]);

  const decodedData: any = React.useMemo(() => {
    if (!data) return

    let decodedData: any = {}
    try {
      // first try to see if it's just an id
      if (data && cData[data]) {
        decodedData = cData[data]
        decodedData.id = data
        return decodedData
      } else if (data?.id) {
        // is this a database query result
        return data;
      }

      // {"t":"tag1,tag2","p":"lat|long|name|placesId|target"}
      try {
        decodedData = data ? JSON.parse(decodeURIComponent(window.atob(data))) : {}
      } catch (e) {
        decodedData = data ? JSON.parse(window.atob(data)) : {}
      }
      //console.log('decodedData', decodedData)

      decodedData.tags = decodedData.t && decodedData.t.length > 0 ? decodedData.t?.split(',').map((tag: string) => tag.trim()) : []
      decodedData.hiddenTags = decodedData.ht && decodedData.ht.length > 0 ? decodedData.ht?.split(',').map((tag: string) => tag.trim()) : []

      if (decodedData.id && cData[decodedData.id]) {
        decodedData.tags = cData[decodedData.id].tags;
        decodedData.hiddenTags = cData[decodedData.id].hiddenTags;
        decodedData.poi = cData[decodedData.id].poi;
        decodedData.logo = cData[decodedData.id].logo;
      } else if (decodedData.p) {
        const poi = decodedData.p.split('|')
        /*
        {
          coordinate: {
            latitude: 52.301419805374906,
            longitude: -1.4496374130249023,
          },
          name: "Hilltop Farm",
          placeId: "ChIJTftkIOszd0gR7gmM2IdfEDg",
          target: 5969,
        }
         */
        decodedData.poi = {
          coordinate: {
            latitude: poi[0],
            longitude: poi[1],
          },
          name: poi[2],
          placeId: poi[3],
          target: poi[4],
        }
      }
    } catch (e) {
      console.error('error decoding data', e)
      decodedData = {}
    }

    return decodedData
  }, [data]);
  //console.log('decodedData', decodedData)

  const tags = decodedData?.tags;
  const hiddenTags = decodedData?.hiddenTags;
  const poi = decodedData?.poi;
  //console.log('tags', tags)
  //console.log('poi', poi)

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [createdUser, setCreatedUser] = React.useState<boolean>(false)
  const [location, setLocation] = React.useState<any>(null)

  //const [phoneNr, setPhoneNr] = React.useState('')
  const [hollr, setHollr] = React.useState('')
  const [terms, setTerms] = React.useState(false)
  const [otp, setOtp] = React.useState('')

  const [submitting, setSubmitting] = React.useState(false)
  const [verifying, setVerifying] = React.useState(false)

  const [hollrSent, setHollrSent] = React.useState(false)
  const [hollrViewPassword, setHollrViewPassword] = React.useState(false)

  React.useEffect(() => {
    // Check if geolocation is available in the browser
    if ("geolocation" in navigator) {
      // Get the user's current location
      navigator.geolocation.getCurrentPosition(function (position) {
        // The user's latitude and longitude are in position.coords.latitude and position.coords.longitude
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setLocation({latitude, longitude})
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      }, function (error) {
        // Handle errors, if any
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.error("User denied the request for geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.error("The request to get user location timed out.");
            break;
        }
      });
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const useEmail = useEmailAddress || email
  const randomPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8)

  const handleSubmitForm = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)
    //console.log('submit', {email, hollr, terms, tags, poi})

    if (!validEmail(useEmail) && !currentUser?.email) {
      alert('Please enter a valid email address')
      setSubmitting(false)
      return
    }

    let user;
    if (currentUser) {
      user = currentUser
      // already logged in ...
    } else {
      if (password) {
        await signInWithEmailAndPassword(auth, useEmail, password)
          .then((userCredential) => {
            // Signed in
            user = userCredential.user;
            //console.log('user', user)
            // ...
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage)
            if (errorCode === 'auth/wrong-password') {
              alert('Incorrect password. Please try again.')
              setShowPassword(true)
            } else {
              alert('An error occurred')
            }
          })
      } else {
        // create user
        await createUserWithEmailAndPassword(auth, useEmail, randomPassword)
          .then(async (createdUser) => {
            const userData: any = {
              id: createdUser.user.uid,
              email: useEmail || '',
              firstName: '',
              lastName: '',
              phone: '',
              profilePictureURL: '',
              userID: createdUser.user.uid,
              appIdentifier: 'l.hollrs.com',
              appHost: document.location.host,
              createdAt: Math.round(+new Date() / 1000),
              createdByAssociateId: data.associateId,
            };
            //console.log('userData', userData)
            await createUserDoc(createdUser.user.uid, userData);

            // Signed in
            user = createdUser.user;
            setCreatedUser(true);
            //console.log('user', user)
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage)
            if (errorCode === 'auth/email-already-in-use') {
              alert('You already have an account with Hollrs. Please sign in with your email and password.')
              if (useEmailAddress === useEmail) {
                setEmail(useEmailAddress);
                setUseEmailAddress('');
              }
              setShowPassword(true)
            } else {
              alert('An error occurred')
            }
          })
      }
    }

    if (!user) {
      setSubmitting(false)
      return
    }

    let pointOfInterest = poi;
    if (!pointOfInterest && location) {
      pointOfInterest = {
        coordinate: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        name: "Current Location",
        placeId: null,
        target: 0,
      }
    }

    // send the hollr to the server
    const post: any = {
      postMedia: [
        {
          mime: 'text',
          text: hollr,
        }
      ],
      appIdentifier: 'l.hollrs.com',
      appHost: document.location.host,
      postText: '',
      displayText: '',
      commentCount: 0,
      reactionsCount: 0,
      reactions: {
        like: 0,
      },
      mentions: [],
      tags,
      hiddenTags,
      pointOfInterest,
    };

    if (data.associateId) {
      // add the given associate to the associateId array
      post.associateId = [data.associateId];
    }

    let postError;
    await addPost(post, user?.uid)
      .catch((error) => {
        console.error(error)
        alert('An error occurred posting your Hollr. Please try again.')
        postError = error
      });

    if (!postError) {
      setHollrSent(true)
    }

    setSubmitting(false)
  }, [currentUser, email, useEmailAddress, password, hollr, terms, tags, poi, data]);

  const handleSubmitOtp = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    //console.log('submit otp', otp)
    setHollrSent(true)
  }, [otp]);

  const handleLogout = React.useCallback(async () => {
    await signOut()
  }, [signOut]);

  const contrast = contrastFromColor(decodedData?.backgroundColor || "#fff")
  let hollrsLogo = "/images/logo-hollrs.png";
  if (contrast === 'light') {
    hollrsLogo = "/images/logo-hollrs-blk.png";
  }

  if (loading) {
    return (
      <div className="App" style={{
        color: "#000",
      }}>
        <div className="App-body" style={{
          backgroundColor: "#fff",
          color: "#000",
        }}>
          <div style={{paddingTop: "40px"}}>
            <img src="/images/loading.gif" alt="Loading..." style={{width: "64px", height: "64px"}}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="App" style={{
      color: decodedData?.color || "#000",
    }}>
      <header className="App-header"></header>
      <div className="App-body" style={{
        backgroundColor: decodedData?.backgroundColor || "#fff",
        color: decodedData?.color || "#000",
      }}>
        <AppBody decodedData={decodedData} compactView={compactView} hollrsLogo={hollrsLogo}>
          {hollrViewPassword ?
            renderHollrViewUsername(decodedData, compactView, hollrsLogo, useEmail, randomPassword)
            :
            (hollrSent ?
              renderHollrSent(decodedData, compactView, hollrsLogo, createdUser ? setHollrViewPassword : null)
              :
              (verifying ?
                renderVerifyingHollr(handleSubmitOtp, ref, otp, setOtp, compactView, submitting)
                :
                (submitting
                    ?
                    renderHollrSubmitting(decodedData, compactView, hollrsLogo)
                    :
                    <form onSubmit={handleSubmitForm} style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}>
                      <div>
                        {decodedData?.subTitle || `What do you want to hollr about?`}
                      </div>
                      <div style={{
                        marginTop: "10px",
                      }}>
                      <textarea
                        value={hollr}
                        placeholder={decodedData?.placeholder || "Hi there. We are looking forward to hearing from you..."}
                        style={{
                          borderRadius: "18px",
                          padding: "20px",
                        }}
                        onChange={(e) => {
                          setHollr(e.target.value)
                        }}
                      />
                      </div>
                      <div style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}>
                        <div>
                          {!!currentUser ?
                            <>
                              <p>You are logged in as {currentUser.email}</p>
                              <p>Is this not you?<br/><a onClick={handleLogout}>Click here to logout</a></p>
                            </>
                            :
                            <>
                              {showPassword &&
                                <p>Please enter your password to send the hollr:</p>
                              }
                              {!useEmailAddress &&
                                <div>
                                  <input
                                    value={email}
                                    type="email"
                                    placeholder="Enter email"
                                    inputMode="email"
                                    onChange={(e) => {
                                      setEmail(e.target.value)
                                    }}
                                  />
                                </div>
                              }
                              {showPassword &&
                                <div>
                                  <input
                                    type="password"
                                    placeholder="Enter password"
                                    onChange={(e) => {
                                      setPassword(e.target.value)
                                    }}
                                  />
                                </div>
                              }
                            </>}
                          {!currentUser && !showPassword &&
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingTop: "10px",
                              textAlign: "left"
                            }}>
                              <label className="checkbox-container" style={{fontSize: compactView ? "12px" : "14px"}}>
                                I accept the <a href="https://hollrs.com/terms" target="_blank" rel="noreferrer">Terms &
                                Conditions</a>
                                <input type="checkbox" checked={terms} onChange={() => setTerms(!terms)}/>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          }
                        </div>
                        <div style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "100%",
                        }}>
                          <div style={{
                            paddingTop: "10px",
                            width: "100%",
                          }}>
                            <button
                              type="submit"
                              style={{
                                fontFamily: "'Odin Rounded', 'Open Sans', sans-serif",
                                fontSize: compactView ? "20px" : "28px",
                                color: "#fff",
                                backgroundColor: "#3d91f6",
                                width: "100%",
                              }}
                              disabled={(!(email || useEmailAddress) && !currentUser) || !hollr || (!terms && !currentUser) || submitting}
                            >
                              hollr
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                )))}
        </AppBody>
      </div>
    </div>
  );
}

export default App;
