export const contrastFromColor = function (color: string) {
  const threshold = 360;

  if (color.length === 4) {
    // short form color, like #fff
    color = '#' + color.substring(1, 3).split('').map(function (hex) {
      return hex + hex;
    }).join('');
  }

  // Use the sRGB luminance formula
  const r = parseInt(color.substring(1, 2), 16);
  const g = parseInt(color.substring(3, 2), 16);
  const b = parseInt(color.substring(5, 2), 16);

  return ((r * 0.299 + g * 0.587 + b * 0.114) > threshold) ? 'light' : 'dark';
}
